:root {
  --white-color: #fff;
  --text-danger: #ff0000;
  --back-bg: #72bbef;
  --lay-bg: #faa9ba;
  --premium-odd-background: #72e3a0;
  --odd-btn-animation-back-color: #c9e7ff;
  --odd-btn-animation-lay-color: #d9afb8;
}

body[data-theme="light"] {
  --primary:#ffb80c;
  --color: #1e1e1e;
  --common-dark: #000;
  --common-text: #212529;
  --primary-linear: linear-gradient(180deg, #ffcc2e 0%, #ffbd14 100%);
  --bg-linear: linear-gradient(180deg, #383838 0%, #010101 100%);
  --bg1-liner: linear-gradient(180deg, #2a3a43 27%, #1c282d 83%);
  --nav-select: #ffdc7a;
  --border: #7e97a7;
  --body: #eee;
  --head: #2a3a43;
  --side-bar: #fff;
  --side-bar1: #3b5160;
  --text1: #212529;
  --text1: #222;
  --link: rgba(0, 0, 0, 0.5);
  --link1: rgba(0, 0, 0, 0.6);
  --select-bg1:#dddcd6;
  --side-bar-text:#fff;

}

body[data-theme="dark"] {
  /* --primary:#0f0840;
  --color: #fff;
  --common-dark: #fff;
  --common-text: #212529;
  --primary-linear: linear-gradient(180deg, #0f0840 0.5%, #010101 100%);
  --bg-linear: linear-gradient(180deg, #0f0840 0%, #010101 100%);
  --bg1-linear: linear-gradient(135deg, #233358, rgba(20, 27, 46, 0.6));
  --nav-select: #0855c4;
  --border: #7e97a7;
  --body: #090f1e;
  --head: #0f0840;
  --side-bar: #141b2e;
  --side-bar1: #1f2841;
  --text1: #fff;
  --text1: #fff;
  --link: #ccc;
  --link1: #ccc;
  --select-bg1:#141b2e;
  --side-bar-text:#212529;; */
  
  --primary:#ffb80c;
  --color: #1e1e1e;
  --common-dark: #000;
  --common-text: #212529;
  --primary-linear: linear-gradient(180deg, #ffcc2e 0%, #ffbd14 100%);
  --bg-linear: linear-gradient(180deg, #383838 0%, #010101 100%);
  --bg1-liner: linear-gradient(180deg, #2a3a43 27%, #1c282d 83%);
  --nav-select: #ffdc7a;
  --border: #7e97a7;
  --body: #eee;
  --head: #2a3a43;
  --side-bar: #fff;
  --side-bar1: #3b5160;
  --text1: #212529;
  --text1: #222;
  --link: rgba(0, 0, 0, 0.5);
  --link1: rgba(0, 0, 0, 0.6);
  --select-bg1:#dddcd6;
  --side-bar-text:#fff;
}
