.login-wrap li{
    position: relative !important;
}
.validation-code{
    color: #000;
    position: absolute;
    top: 3px;
    right: 0px;
    width: 50px;
    height: 15px;
    font-size: 16px;
    font-weight: bolder;
}

.validation-code-modal{
    color: #000;
    position: absolute;
    top: 8px;
    right: 5px;
    width: 55px;
    height: 15px;
    font-size: 20px;
    font-weight: bolder;
}